<template>
    <footer animated fadeUp>
        <div class="container">
            <a href="/"><img class="logo" src="../../assets/logo.png" alt="Logo falcare"></a>
            <ul>
                <li><a href="gr_therapeutique">Groupes thérapeutique</a></li>
                <li><a href="facturation">Facturation</a></li>
                <li><a href="emploi">Emplois</a></li>
                <li><a href="contact">Contact</a></li>
            </ul>
            <ul>
                <li>FAL.Care Sàrl</li>
                <li>+41 77 221 03 77 /  +41 76 781 44 71</li>
                <li>info@falcare.ch</li>
            </ul>
            <ul class="right">
                <li>Siège - Rue de la Gare 7, 2502 Biel/Bienne</li>
                <li>Rue du Marché 4, 2300 La Chaux-de-Fonds</li>
                <li>Rue de L'Écluse 38, 2000 Neuchâtel</li>
                <li>Avenue Léopold Robert 9, 2300 La Chaux-de-Fonds</li>
            </ul>
        </div>
        <p>tous droits réservé à ©FAL.Care Sàrl</p>
    </footer>
</template>

<script>

    export default {
        name: 'MainFooter',
        data() {
            return {
                //
            }
        },
        methods: {
            //
        }
    }

</script>

<style scoped>
    footer {
        width:100%;
        height:25vh;   /* Height of the footer */
        min-height: 300px;

        padding: 4em;

        background-color: #49584C;
        color: var(--white);

        display: flex;
        flex-direction: column;

        position: relative;
    }

    footer div.container{
        height: 100%;
        width: 100%;
        max-width: 2000px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0;
        padding: 0;
    }


    footer p{
        text-align: center;
        /* Tous droits réservé à ©FAL.Care Sàrl */

        font-family: 'Archia';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #FFFFFF;

        position: absolute;
        bottom: 0.5em;
        left: 50%;
        transform: translateX(-50%);


    }

    footer div.container ul{
        width: 50%;
        margin: 0;
        padding: 0;
        display: inline-block;
        text-align: left;
    }

    li{

        font-family: 'Archia';
        font-style: normal;
        font-weight: 400;
        font-size: 0.8em;
        line-height: 24px;

        color: #FFFFFF;


    }

    img.logo{
        height: 100%;
    }

    a {
        height: 100%;
    }

    @media screen and (max-width: 600px) {
        footer {
            font-size: small;
            flex-direction: column;
            padding: 2em;
            height: fit-content;
            
        }

        img.logo{
            height: auto;
            width: 60%;
            margin: auto;
            text-align: center;
        }


        footer div.container ul{
            width: 100%;
            margin: auto;
            display: block;
        }
        
        footer ul.right{
            float: left;
            text-align:left;
        }

        div.container{
            height: fit-content;
            flex-direction: column-reverse;
            margin-bottom: 30px !important;
            
        }
        div.container > a{
            text-align: center;
        }

        div.container > ul{
           border-top: 1px solid var(--white);
        }

        /* laste child no border top */
        div.container > ul:last-child{
            border-top: none;
        }
    }
</style>