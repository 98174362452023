<template>
    <MainHeader/>
    <div class="content">
    <div id="contact" class="container">
        <br>
        <h1>Contact</h1>
        <div class="wrapper">
            <form ref="formulaire" @submit.prevent="Mailto">
                <input type="text" id="prenom" name="prenom" placeholder="Prénom">

                <input type="text" id="nom" name="nom" placeholder="Nom">

                <input type="email" id="email" placeholder="Email">

                <textarea id="message" name="message" rows="4" cols="46" placeholder="Message"></textarea>
                    
                <button type="submit" name="submit"><i class="material-icons">send</i></button>
            </form>
            <div class="right-part">
                <span><i class="material-icons"> place</i> 
                  <ul>
                    <li>Siège - Rue de la Gare 7, 2502 Biel/Bienne</li>
                    <li>Rue du Marché 4, 2300 La Chaux-de-Fonds</li>
                    <li>Rue de L'Écluse 38, 2000 Neuchâtel</li>
                    <li>Avenue Léopold Robert 9, 2300 La Chaux-de-Fonds</li>
                  </ul> 
                </span>
                <span><i class="material-icons">call</i>+41 77 221 03 77 /  +41 76 781 44 71</span>
                <span><i class="material-icons">email</i>info@falcare.ch </span>
                <hr>
                <div>
                    <a href="https://www.instagram.com/fal.care.sarl/"> <img src="@/assets/emote/instagram_icon.png" alt="Snow" style="width:100%;"></a>
                    <a href="https://www.facebook.com/LaPsychiatriePourTous"> <img src="@/assets/emote/facebook_icon.png" alt="Snow" style="width:100%;"></a>
                </div>
                <hr>
                <p>Tous droits réservés © FAL.Care Santé Mentale</p>
            </div>
        </div>
    </div>
</div>
<MainFooter/>
</template>

<script>
import MainHeader from '../global/MainHeader.vue';
import MainFooter from '../global/MainFooter.vue';

export default {
    name: 'ContactMain',
    data() {
        return {
            //
        }
    },
    methods: {
        Mailto() {
            let formulaire = this.$refs.formulaire;

            const to = 'info@falcare.ch';
            const subject = 'Prise de contact';
            let body = 'Bonjour, je m\'appelle ' + formulaire.prenom.value + ' ' + formulaire.nom.value + ' et je vous contacte pour ';
            body += formulaire.message.value;

            window.location = 'mailto:' + to + '?subject=' + subject + '&body=' + body;
        }
    },
    components: {
        MainHeader,
        MainFooter
    }
}
</script>

<style scoped>
div#contact {
    background-color: var(--grey-green);
    color: white;
    padding: 5vh;
    margin-top: 5vh;
    margin-bottom: 5vh;

    box-shadow: 0 3px 10px rgb(0 0 0 / 0.4);
    border-radius: 10px;
}

div#contact > div.wrapper {
    display: flex;
    padding: 5vh;
    column-gap: 5vh;
}

div#contact > div.wrapper > form, div#contact > div.wrapper > div{
    flex: 50%;
}

div#contact > div.wrapper > div.right-part span{
    display: block;
    margin-bottom: 2vh;
}

div#contact > div.wrapper > div.right-part > div{
    display: flex;
    justify-content: center;
    column-gap: 5%;
}

div#contact > div.wrapper > div.right-part > div  img{
    filter: brightness(0) invert(1);
    
}

div#contact > div.wrapper > div.right-part > div > a{
    width: 5vh;
    height: 5vh;
    display: flex;
    justify-content: center;
    align-items: center;    
}

div#contact > div.wrapper > div.right-part > span  > i{
    margin-right: 2vh;
}

div#contact > div.wrapper > form > input, div#contact > div.wrapper > form > textarea{
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid var(--white);
    margin: 1vh 2vh;
    margin-left: 0px;
    color: var(--white);
}

div#contact > div.wrapper > form > button {
    display: block;
    background-color: rgba(0, 0, 0, 0);
    border: 0px solid var(--white);
    color: var(--white);
    float: right;
    font-size: 40px;
    margin-right: 3vh;
}

#email, #message{
    width: 100%;
}

@media screen and (max-width: 600px) {
    div#contact > div.wrapper {
        flex-direction: column;
        row-gap: 2vh;
        padding: 0vh;
    }
}
</style>